import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import dayjs from 'dayjs'
import _ from 'lodash'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { ArrowPathIcon } from '@heroicons/react/24/solid'

// Components
import { Button } from '../../components/Button'
import { CheckBox } from '../../components/CheckBox'
import { DataTable } from '../../components/DataTable'
import { EventHeader } from '../../components/EventHeader'
import { Modal } from '../../components/Modal'
import { Select } from '../../components/Select'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'
import { Tooltip } from '../../components/Tooltip'

// Icons
import Key from '../../assets/images/key.svg'

// Stores
import { NavigationStoreContext } from '../../stores/NavigationStore'

// Services
import { getEvent, updateEvent } from '../../services/events.service'

// Utils
import { toast } from '../../utils/helpers'

let interval = null

/**
 *
 * EventApiConfig
 *
 */
const EventApiConfig = observer(() => {
  // Context
  const { event, eventId, organizationId, setEvent } = useContext(NavigationStoreContext)

  // State
  const [loadingRegistration, setLoadingRegistration] = useState(false)
  const [loadingCustomFields, setLoadingCustomFields] = useState(false)
  const [loadingReset, setLoadingReset] = useState(false)
  const [pingEvent, setPingEvent] = useState(false)
  const [checkingStatus, setCheckingStatus] = useState(false)
  const [showResetConfirm, setShowResetConfirm] = useState(false)

  // Custom Field Sync
  const [syncInProgress, setSyncInProgress] = useState(false)
  const [customFields, setCustomFields] = useState([])
  const [selectedFields, setSelectedFields] = useState([])

  const handleErrors = (m) => toast(m, 'error')
  const handleSuccesses = (m) => toast(m, 'success')

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    defaultValues: {
      registrationType: null,
      apiKey: null,
      externalAccountId: null,
      externalEventId: null,
    },
  })

  /**
   * Triggers on first load to see if we need to monitor the registration status for this event.
   */
  useEffect(() => {
    if (event.enableRegistrationSync && event.apiKey && !event.availableCustomFields) {
      setPingEvent(true)
    }

    reset({
      registrationType: { label: 'Stova', value: 'Stova' },
      apiKey: event.apiKey,
      externalAccountId: event.externalAccountId,
      externalEventId: event.externalEventId,
    })
  }, [])

  useEffect(() => {
    if (event.availableCustomFields) {
      const fieldNames = _.keys(event.availableCustomFields)
      const fields = _.map(_.values(event.availableCustomFields), (v, i) => ({
        name: fieldNames[i],
        ...v,
      }))

      setCustomFields(fields)
      setSelectedFields(event.enabledCustomFields || [])
    }
  }, [event])

  useEffect(() => {
    if (pingEvent) {
      setCheckingStatus(true)

      if (interval === null) {
        interval = setInterval(async () => {
          const updatedEvent = await getEvent(organizationId, eventId)
          setEvent(updatedEvent)

          if (
            updatedEvent.availableCustomFields ||
            updatedEvent.attendeesSyncStatusMessage ||
            updatedEvent.customFieldsSyncStatusMessage
          ) {
            clearInterval(interval)
            setCheckingStatus(false)
            setPingEvent(false)
          }
        }, 3000)
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [pingEvent])

  /**
   * Handles submitting the registration sync form to initiate the sync process.
   * @param {object} data
   */
  const onRegistrationSubmit = async (data) => {
    const updatedData = {
      ...data,
      registrationType: data.registrationType.value,
      triggerAttendeeSyncAllTime: true,
    }

    // If the api key includes `*`, then it is already set and
    // we don't want to update it.
    if (updatedData.apiKey.includes('*')) {
      delete updatedData.apiKey
    }

    const result = await updateEvent(
      { id: eventId, ...updatedData },
      handleErrors,
      setLoadingRegistration,
      () => {},
    )

    if (result) {
      handleSuccesses('Registration sync initiated.')
      setPingEvent(true)
    }
  }

  /**
   * Handles submitting the merge fields form to update the selected custom fields.
   */
  const onMergeFieldsSubmit = async () => {
    const result = await updateEvent(
      { id: eventId, enabledCustomFields: selectedFields, triggerCustomFieldsSync: true },
      handleErrors,
      setLoadingCustomFields,
      () => {},
    )

    if (result) {
      handleSuccesses('Merge field sync initiated.')
      setEvent(result)
      setSyncInProgress(true)
    }
  }

  /**
   * Handles submitting a reset registration request for the event.
   */
  const onResetSubmit = async () => {
    const result = await updateEvent(
      { id: eventId, triggerResetRegistrationSync: true },
      handleErrors,
      setLoadingReset,
      () => {},
    )

    if (result) {
      handleSuccesses('Registration sync reset.')
      setEvent(result)

      // Clear out all monitoring
      reset()
      setShowResetConfirm(false)
      setPingEvent(false)
      setCheckingStatus(false)
    }
  }

  const hasCompletedRegistrationConfig =
    event &&
    event.registrationType &&
    event.apiKey &&
    event.externalAccountId &&
    event.externalEventId

  const syncError =
    event && (event.customFieldsSyncStatusMessage || event.attendeesSyncStatusMessage)

  return (
    <div className="h-full w-full">
      <StateContainer>
        <div className="relative flex h-full w-full flex-col space-y-3 p-3">
          <div className="flex flex-row place-items-center justify-between">
            <EventHeader event={event} />
          </div>

          <span className="text-md font-bold">API Config</span>

          <div className="shadow-xm flex h-[93%] w-full flex-col overflow-hidden rounded-[15px] bg-white px-5 py-4">
            <div className="mb-4 flex flex-col">
              <div className="mb-2 flex flex-col items-start justify-between space-y-1 sm:flex-row sm:space-y-0">
                <span className="text-md font-bold">Registration Details</span>

                <div className="flex flex-col space-y-1">
                  {hasCompletedRegistrationConfig && (
                    <Button
                      className="border-red bg-white text-red hover:bg-white"
                      fullWidth
                      onClick={() => setShowResetConfirm(true)}
                      outlined
                      label="Reset API Connection"
                    />
                  )}

                  {event.attendeesLastSyncedAt && !event.attendeesSyncStatusMessage && (
                    <span className="text-xs">
                      Last Updated {dayjs(event.attendeesLastSyncedAt).format('MM/DD/YYYY h:mm A')}
                    </span>
                  )}

                  {event.attendeesSyncStatusMessage && (
                    <span className="text-xs text-error">{event.attendeesSyncStatusMessage}</span>
                  )}
                </div>
              </div>

              <div className="flex w-full flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0 md:justify-between">
                <div className="relative mt-1 flex w-full flex-col space-y-2 sm:w-1/2 md:max-w-md">
                  <Controller
                    name="registrationType"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                        data-testid="registrationType"
                        disabled={
                          loadingRegistration || checkingStatus || hasCompletedRegistrationConfig
                        }
                        error={errors.registrationType && 'This field is required'}
                        id="registrationType"
                        name="registrationType"
                        nunito
                        onChange={onChange}
                        options={[{ label: 'Stova', value: 'Stova' }]}
                        placeholder="Select a Registration System"
                        style={{ flex: true, width: '100%' }}
                        value={value}
                      />
                    )}
                    rules={{ required: true }}
                  />

                  <TextInput
                    className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                    disabled={
                      loadingRegistration || checkingStatus || hasCompletedRegistrationConfig
                    }
                    icon={<img alt="Key" className="ml-1.5 h-4" src={Key} />}
                    data-testid="api-key"
                    error={errors.apiKey && 'This field is required'}
                    fullWidth
                    id="apiKey"
                    inputStyles="rounded-none font-nunito text-md sm:text-base"
                    name="apiKey"
                    nunito
                    placeholder="API Key"
                    {...register('apiKey', { required: true })}
                  />
                </div>

                <div className="flex w-full flex-col space-y-2 sm:w-1/2 md:max-w-md">
                  <TextInput
                    className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                    disabled={
                      loadingRegistration || checkingStatus || hasCompletedRegistrationConfig
                    }
                    icon={<img alt="Key" className="ml-1.5 h-4" src={Key} />}
                    data-testid="externalAccountId"
                    error={errors.externalAccountId && 'This field is required'}
                    fullWidth
                    id="externalAccountId"
                    inputStyles="rounded-none font-nunito text-md sm:text-base"
                    name="externalAccountId"
                    nunito
                    placeholder="Account ID"
                    {...register('externalAccountId', { required: true })}
                  />

                  <TextInput
                    className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                    disabled={
                      loadingRegistration || checkingStatus || hasCompletedRegistrationConfig
                    }
                    icon={<img alt="Key" className="ml-1.5 h-4" src={Key} />}
                    data-testid="externalEventId"
                    error={errors.externalEventId && 'This field is required'}
                    fullWidth
                    id="externalEventId"
                    inputStyles="rounded-none font-nunito text-md sm:text-base"
                    name="externalEventId"
                    nunito
                    placeholder="Event ID"
                    {...register('externalEventId', { required: true })}
                  />
                </div>
              </div>

              {!hasCompletedRegistrationConfig && (
                <Button
                  background="bg-purple border-purple hover:bg-purple-600"
                  className="mt-4 self-end"
                  loading={loadingRegistration}
                  label="Connect to Registration API"
                  onClick={handleSubmit(onRegistrationSubmit)}
                />
              )}
            </div>

            {pingEvent && !syncError && (
              <div className="flex h-full w-full flex-col items-center justify-center space-y-2">
                <span className="text-2xl font-bold">Syncing merge fields...</span>
                <span className="flex items-center pr-3">
                  <div className="h-10 w-10">
                    {/* eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order */}
                    <svg className="h-10 w-10 motion-safe:animate-spin-slow" viewBox="0 0 40 40">
                      <ArrowPathIcon className="h-10 w-10" aria-hidden="true" />
                    </svg>
                  </div>
                </span>
              </div>
            )}

            {event.availableCustomFields && (
              <div className="mt-4 flex h-full grow flex-col space-y-2 overflow-hidden">
                <div className="flex flex-col items-start justify-between space-y-1 sm:flex-row sm:space-y-0">
                  <span className="text-md font-bold">Available Merge Fields</span>

                  <div className="flex flex-col space-y-1">
                    <Tooltip
                      content={
                        <div className="rounded-lg bg-white px-2 pb-0.5">
                          <span className="text-xs">Sync is in progress.</span>
                        </div>
                      }
                      display={syncInProgress}
                      fullWidth
                      placement="left"
                    >
                      <Button
                        background="bg-purple border-purple hover:bg-purple-600"
                        disabled={syncInProgress}
                        fullWidth
                        label="Resync Merge Fields"
                        loading={loadingCustomFields}
                        onClick={onMergeFieldsSubmit}
                      />
                    </Tooltip>

                    {event.customFieldsLastSyncedAt && !event.customFieldsSyncStatusMessage && (
                      <span className="text-xs">
                        Last Updated{' '}
                        {dayjs(event.customFieldsLastSyncedAt).format('MM/DD/YYYY h:mm A')}
                      </span>
                    )}

                    {event.customFieldsSyncStatusMessage && (
                      <span className="text-xs text-error">
                        {event.customFieldsSyncStatusMessage}
                      </span>
                    )}
                  </div>
                </div>

                <DataTable
                  columns={[
                    {
                      id: 'select',
                      width: '135px',
                      name: (
                        <div className="flex flex-row">
                          <CheckBox
                            className="ml-2"
                            onChange={() => {
                              if (
                                selectedFields.length === 0 ||
                                selectedFields.length < customFields.length
                              ) {
                                setSelectedFields(_.map(customFields, 'name'))
                              } else {
                                setSelectedFields([])
                              }
                            }}
                            value={selectedFields.length === customFields.length}
                          />
                          <span className="text-xs uppercase">Select All</span>
                        </div>
                      ),
                      selector: (row) => `${row.firstName} ${row.lastName}`,
                      cell: (row) => (
                        <div className="">
                          <CheckBox
                            className="ml-2"
                            onChange={() => {
                              if (selectedFields.includes(row.name)) {
                                setSelectedFields(selectedFields.filter((f) => f !== row.name))
                              } else {
                                setSelectedFields([...selectedFields, row.name])
                              }
                            }}
                            value={selectedFields.includes(row.name)}
                          />
                        </div>
                      ),
                      sortable: false,
                    },
                    {
                      id: 'field',
                      grow: 1,
                      name: 'Field Name',
                      selector: (row) => row.name,
                      sortable: true,
                    },
                    {
                      id: 'visibility',
                      grow: 1,
                      name: 'Visible',
                      selector: (row) => (row.questionId ? 'Yes' : 'No'),
                      sortable: true,
                    },
                  ]}
                  data={customFields}
                  defaultSortFieldId="name"
                  defaultSortAsc
                  progressPending={loadingCustomFields}
                />
              </div>
            )}
          </div>
        </div>
      </StateContainer>

      <Modal
        actions={[
          {
            type: 'cancel',
            label: 'Cancel',
          },
          {
            type: 'submit',
            label: 'Confirm',
            background: 'bg-red',
            onClick: () => onResetSubmit(),
          },
        ]}
        icon={<ExclamationTriangleIcon className="h-8 text-white" />}
        iconBackground="bg-red"
        content={
          <div className="mt-3 flex flex-col space-y-2 text-center sm:mt-5">
            <span className="text-sm">
              If you reset the API connection, all currently synced data will be deleted, including
              Merge Fields and Attendees.
            </span>

            <span className="text-sm">Do you want to continue and reset the API connection?</span>
          </div>
        }
        loading={loadingReset}
        open={showResetConfirm}
        setOpen={setShowResetConfirm}
        title="Are you sure?"
      />
    </div>
  )
})

export default EventApiConfig
