import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Parser } from 'html-to-react'
import _ from 'lodash'

// Components
import { Modal } from '../Modal'
import { ThumbsUpIcon } from '../ThumbsUpIcon'

// Utils
import { replaceMergeFields } from './helpers'

/**
 *
 * WelcomePopup
 *
 */
const WelcomePopup = ({
  attendee,
  config,
  completeCheckin,
  customPopups,
  close,
  externalPopupField,
  staffMode,
}) => {
  // Auto close after 10 seconds unless there are custom popup messages to display
  useEffect(() => {
    completeCheckin()

    const timeout = setTimeout(() => {
      if (customPopupsToRender.length === 0) close()
    }, [10000]) // 10 seconds

    return () => clearTimeout(timeout)
  }, [])

  /**
   * Handles determining if the popup message should be included.
   * @param {object} popupMessageLogic
   */
  const includeMessage = (popupMessageLogic) => {
    const testArray = popupMessageLogic.map((logic) => {
      if (
        logic.operator === 'is' &&
        (_.lowerCase(attendee[logic.mergeField]) === _.lowerCase(logic.value) ||
          (attendee.customData &&
            _.lowerCase(attendee.customData[logic.mergeField]) === _.lowerCase(logic.value)))
      ) {
        return true
      }
      if (
        logic.operator === 'contains' &&
        (_.includes(attendee[logic.mergeField], logic.value) ||
          (attendee.customData && _.includes(attendee.customData[logic.mergeField], logic.value)))
      ) {
        return true
      }
      if (
        logic.operator === 'populated' &&
        (attendee[logic.mergeField] ||
          (attendee.customData && attendee.customData[logic.mergeField]))
      ) {
        return true
      }
      return false
    })

    if (popupMessageLogic[0].inclusionLogic === 'OR') {
      return testArray.includes(true)
    }
    return testArray.every((test) => test)
  }

  const customPopupsToRender = customPopups.filter(
    (popup) =>
      includeMessage(popup.popupMessageLogic) &&
      (popup.audience === 'Both' ||
        (popup.audience === 'Attendee' && !staffMode) ||
        (popup.audience === 'Staff' && staffMode)),
  )

  return (
    <Modal
      actions={[
        {
          type: 'cancel',
          label: 'Close',
          onClick: close,
        },
      ]}
      content={
        <div className="mt-4 flex flex-col items-center">
          <span className="text-center text-2xl font-bold">
            {replaceMergeFields(attendee, config.header)}
          </span>
          <p className="mt-4 text-center text-gray">
            {Parser().parse(replaceMergeFields(attendee, config.body))}
          </p>

          {externalPopupField && attendee.customData[externalPopupField] && (
            <p className="mt-4 text-center text-gray">{attendee.customData[externalPopupField]}</p>
          )}

          {staffMode && attendee.customNotes && (
            <p className="mt-4 text-center text-gray">{attendee.customNotes}</p>
          )}

          <ul className={customPopupsToRender.length > 1 && 'list-disc'}>
            {customPopupsToRender.map((popup) => (
              <li key={popup.id} className="mt-4 text-center text-gray">
                {Parser().parse(replaceMergeFields(attendee, popup.message))}
              </li>
            ))}
          </ul>
        </div>
      }
      icon={<ThumbsUpIcon className="h-6 w-6 fill-white" />}
      open
    />
  )
}

WelcomePopup.defaultProps = {
  externalPopupField: null,
  staffMode: false,
}

WelcomePopup.propTypes = {
  attendee: PropTypes.object.isRequired,
  completeCheckin: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  externalPopupField: PropTypes.string,
  staffMode: PropTypes.bool,
  customPopups: PropTypes.array.isRequired,
}

export { WelcomePopup }
